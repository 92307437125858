<template>
  <div class="card">
    <!-- Access to users -->
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Acces to users</a>
        <!-- <span
          class="dis-ib ms-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Go to add mail templates"
        ><a
          class="btn btn-icon rounded-circle btn-flat-dark"
          @click="$router.push({name: 'admin.mail-templates.index'})"
        ><i data-feather="plus" /></a></span> -->
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseSection-4`"
              data-action="collapse"
            ><i
              data-feather="chevron-down"
            /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseSection-4`"
        visible
      >
        <div class="card-body">
          <div
            v-for="ac in access"
            :key="ac.id"
            class="row"
          >
            <div
              class="col-sm-3 d-flex align-items-center justify-content-between"
            >
              <label
                class="form-check-label ms-1"
                for="applicantsRow"
              >
                {{ ac.name }}</label>
            </div>
            <div
              class="
                col-sm-3
                d-flex
                align-items-center
                justify-content-between
                position-relative
              "
            >
              <div class="me-1">
                in date:
              </div>
              <div class="flex-grow-1">
                <date-picker
                  format="D MMM Y HH:mm:ss"
                  value-type="format"
                  class="w-100"
                  :lang="lang"
                  :value="convo.access && convo.access.find(e => e.access_id === ac.id) ? convo.access.find(e => e.access_id === ac.id).start_date : null"
                  @change="submitAnswer($event, 'start_date', ac.id)"
                  @input-error="transformDate($event, 'start_date', ac.id)"
                />
                <!-- <CustomInput
                  :name="`access[${ac.id}]['start_date']`"
                  :parameter="{ type: { name: 'date' } }"
                  :answer="{
                    value: getProperty('start_date', convo.access, ac.id),
                  }"
                  @submitAnswer="submitAnswer($event, 'start_date', ac.id)"
                /> -->
              </div>
            </div>
            <div
              class="
                col-sm-3
                d-flex
                align-items-center
                justify-content-between
                position-relative
              "
            >
              <div class="me-1">
                to date:
              </div>
              <div class="flex-grow-1 me-1">
                <date-picker
                  format="D MMM Y HH:mm:ss"
                  value-type="format"
                  class="w-100"
                  :lang="lang"
                  :value="convo.access && convo.access.find(e => e.access_id === ac.id) ? convo.access.find(e => e.access_id === ac.id).end_date : null"
                  @change="submitAnswer($event, 'end_date', ac.id)"
                  @input-error="transformDate($event, 'end_date', ac.id)"
                />
                <!-- <CustomInput
                  :name="`access[${ac.id}]['end_date']`"
                  :parameter="{ type: { name: 'date' } }"
                  :answer="{
                    value: getProperty('end_date', convo.access, ac.id),
                  }"
                  @submitAnswer="submitAnswer($event, 'end_date', ac.id)"
                /> -->
              </div>
              <div>
                <a
                  class="btn btn-icon rounded-circle btn-flat-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Copy"
                  @click="copyDates(ac.id)"
                ><i
                  data-feather="copy"
                /></a>
              </div>
              <div>
                <a
                  class="btn btn-icon rounded-circle btn-flat-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Paste"
                  @click="pasteDates(ac.id)"
                ><i
                  data-feather="clipboard"
                /></a>
              </div>
            </div>
            <!-- <div class="col-sm-3 align-items-end">
              <v-select
                v-model="ac.template"
                label="title"
                :options="templates"
                :clearable="false"
                :get-option-key="option => option.id"
                @search="$store.dispatch('mailTemplates/filter', { name: $event })"
                @input="submitAnswer($event, 'template', ac.id)"
              />
            </div> -->
            <hr class="divider">
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
    vSelect,
  },
  data() {
    return {
      changeAccess: [],
      copyStartDate: null,
      copyEndDate: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      access: 'access/accesses',
      templates: 'mailTemplates/items',
    }),
  },
  watch: {
    access() {
      if (this.access.length > 0) {
        this.loadTemplates()
      }
    },
  },
  methods: {
    getProperty(name, access, accessId) {
      if (access) {
        for (let i = 0; i < access.length; i += 1) {
          if (access[i].access_id === accessId) {
            return access[i][name]
          }
        }

        return null
      }
      return null
    },
    submitAnswer(data, type, id) {
      const access = this.convo.access && this.convo.access.find(x => x.access_id === id)
      if (!access) {
        const obj = {
          access_id: id,
        }
        obj[type] = data
        if (!this.convo.access) {
          this.convo.access = []
        }

        this.convo.access.push(obj)
      } else {
        this.convo.access.find(x => x.access_id === id)[type] = data
      }

      this.$emit('saveAnswer', { id: 'access', data: this.convo.access })
      this.$forceUpdate()
    },
    copyDates(accessId) {
      const access = this.convo.access.find(x => x.access_id === accessId)
      this.copyEndDate = access.end_date
      this.copyStartDate = access.start_date
    },
    pasteDates(accessId) {
      const access = this.convo.access.find(x => x.access_id === accessId)

      if (access) {
        access.end_date = this.copyEndDate
        access.start_date = this.copyStartDate
      } else {
        const obj = {
          access_id: accessId,
          end_date: this.copyEndDate,
          start_date: this.copyStartDate,
        }

        this.convo.access.push(obj)
      }

      this.$emit('saveAnswer', { id: 'access', data: this.convo.access })
      this.$forceUpdate()
    },
    loadTemplates() {
      this.access.forEach((item, index) => {
        this.access[index].template = this.getProperty('template', this.convo.access, item.id)
      })
    },
    transformDate(date, type, id) {
      const newDate = moment(date, 'DD/MM/YYYY HH:mm:ss').format('D MMM Y HH:mm:ss')
      this.submitAnswer(newDate, type, id)
    },
  },
}
</script>
