<template>
  <div class="card"> <!-- Basic information -->
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Basic information {{ convo.title }}</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseSection-1`"
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseSection-1`"
        visible
      >
        <div class="card-body">
          <div class="row">
            <div
              :class="{ 'col-sm-6': type != 'promotion', 'col-sm-8': type == 'promotion'} "
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['basic_information.title'] }}</label>
                <input
                  class="form-control"
                  :value="convo.title"
                  type="text"
                  @change="submitAnswer($event.target.value, 'title')"
                >
              </div>
            </div>
            <div class="col-sm-2">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['basic_information.code'] }}</label>
                <input
                  class="form-control"
                  :value="convo.code"
                  type="text"
                  @change="submitAnswer($event.target.value, 'code')"
                >
              </div>
            </div>
            <div class="col-sm-2">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['basic_information.year'] }}</label>
                <Select
                  :name="'year'"
                  :parameter="availableYears"
                  :answer="convo.id ? {value: convo.year} : null"
                  :edit="true"
                  @submitAnswer="submitAnswer($event, 'year')"
                />
              </div>
            </div>
            <div
              v-if="type != 'promotion'"
              class="col-sm-2"
            >
              <div class="mb-1 form-group">
                <label
                  for=""
                  class="form-label"
                >{{ labels['basic_information.allow_retrieves'] }}</label>
                <br>
                <input
                  type="checkbox"
                  :checked="convo.allow_retrieves"
                  class="form-checkbox-control"
                  @click="submitAnswer($event.target.checked, 'allow_retrieves')"
                >
              </div>
            </div>
            <!-- <div class="col-sm-3">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >ID ICREA</label>
                <input
                  class="form-control"
                  :value="convo.icrea_id"
                  type="text"
                  @change="submitAnswer($event.target.value, 'icrea_id')"
                >
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['basic_information.description'] }}</label>
                <TextArea
                  :name="'description'"
                  :answer="{value: convo.description}"
                  :edit="true"
                  @submitAnswer="submitAnswer($event, 'description')"
                />
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Select from '../../../../convos/partials/input-types/Select.vue'
import TextArea from '../../../../convos/partials/input-types/TextArea.vue'

export default {
  components: {
    Select,
    BCollapse,
    TextArea,
  },
  props: {
    type: { type: String, required: false, default: '' },
  },
  data() {
    return {
      availableYears: {
        values: [],
      },
      basic: {},
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'calls.calls')
    for (let index = 2000; index < 2030; index++) {
      this.availableYears.values.push({ id: index, default_value: index })
    }
  },
  methods: {
    submitAnswer(data, type) {
      this.basic[type] = data
      this.$emit('saveAnswer', { id: 'info', data: this.basic })
    },
  },
}
</script>
